import Cookies from '/assets/js/js-cookie.js';

const setCookie = (name, value, time) => {
  const getLPCookie = Cookies.get(name);
  if (!getLPCookie) {
    Cookies.set(name, value, {expires: time});
  }
}

setCookie("EntryURL", document.URL, 1)
setCookie("EntryReferrer", document.referrer, 1)

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
//let value = params.some_key; // "some_value"
if (params.utm_source != null) { 
  setCookie("Utm_Source", params.utm_source, 1)
}
if (params.utm_campaign != null) { 
setCookie("Utm_Campaign", params.utm_campaign, 1)
}
if (params.utm_medium != null) { 
setCookie("Utm_Medium", params.utm_medium, 1)
}
if (params.utm_content != null) { 
  setCookie("Utm_Content", params.utm_content, 1)
}
